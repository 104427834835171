import { alpha } from 'src/theme/palette';
import { useRouter } from 'next/router';
import { ROUTES } from 'src/config';
import useAuthPersistStore, {
  AuthStoreType
} from 'src/hooks/store/useAuthPersistStore';
import { useSettingsStore } from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

export function Logo() {
  const themeMode = useSettingsStore();
  const palette = getPalette(themeMode);
  return (
    <div
      style={{
        background: alpha(palette.primary.main, 1),
        width: '283px',
        height: '200px',
        WebkitMaskImage: 'url(/static/emitiq/eiq_logo.svg)',
        maskImage: 'url(/static/emitiq/eiq_logo.svg)',
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)'
      }}
    />
  );
}

export function TopbarLogo() {
  const {
    authState: { isAuthenticated }
  } = useAuthPersistStore((store: AuthStoreType) => ({
    authState: store.authState
  }));
  const router = useRouter();
  return (
    <div
      className="cursor-pointer px-lg lg:px-xl xl:px-lg"
      onClick={() => {
        isAuthenticated
          ? router.push(ROUTES.DASHBOARD)
          : router.push(ROUTES.ROOT);
      }}
    >
      <div className="w-[140px]">
        <img
          src={'/static/emitiq/eiq_logo_horz.svg'}
          className="w-full rounded-lg object-fill"
        />
      </div>
    </div>
  );
}
